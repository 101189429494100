import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BaseHandle.css";
import { useTranslation } from "react-i18next";
import packageJson from '../../package.json';

function HandleJsonUpload() {
  const [showNotification, setShowNotification] = useState(false);
  const { t } = useTranslation();

  const inputRef3 = useRef(null);
  const handleInputClick3 = () => {
    if (inputRef3.current) {
      inputRef3.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Hide notification after 2 seconds
    }
  };

  const handleFocusAndClick = (e) => {
    e.target.select();
  };

  const [file, setFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      // Giới hạn 5MB
      alert("Upload limit is 5MB !");
    } else {
      setFile(file);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${packageJson.proxy}/upload`, {
        method: "POST",
        body: formData, // Gửi dữ liệu dưới dạng FormData
      });

      const data = await response.json();
      setUploadedFileName(data.fileName);
      alert("File uploaded successfully!");
    } catch (err) {
      console.error("Error uploading file:", err);
      alert("Failed to upload file.");
    }
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <h2 className="title">{t("jsonupload")}</h2>
          </div>
          <div className="col-sm-8" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <div className="input-group">
              <input
                type="file"
                accept=".json"
                className="form-control"
                onChange={handleFileChange}
              />
              <button onClick={handleUpload} className="btn btn-primary ml-2">
                Upload
              </button>
            </div>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h2 className="final-command">{t("command")}</h2>
          </div>
          <div className="col-sm-9" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            {uploadedFileName && (
              <input
                type="text"
                value={`/json ${packageJson.proxy}/${uploadedFileName}`}
                readOnly
                ref={inputRef3}
                className="command"
                onClick={handleInputClick3}
                onFocus={handleFocusAndClick}
              />
            )}
          </div>
          <div className="col-sm-5" />
        </div>
      </div>
      {showNotification && (
        <div className="notification">{t("copySuccess")}</div>
      )}
      <div style={{ marginTop: "100px" }} />
    </>
  );
}

export default HandleJsonUpload;
