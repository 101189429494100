import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./cmdbeginner.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function HandleCmdbeginner() {
  const [selectedButton, setSelectedButton] = useState("");
  const inputRef = useRef(null);
  // eslint-disable-next-line
  const [showInput, setShowInput] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { t, i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Hide the message after 2 seconds
    }
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonNameMapping[buttonName] || buttonName);
    setShowInput(false); // Ẩn ô input number khi chọn nút mới
  };

  const buttonNameMapping = {
    10: "/giveall materials",
    1: "/prop setlevel 70",
    2: "/prop worldlevel 6",
    3: "/giveall avatars lv80 r6 s10",
    4: "/giveall lightcones lv80 r5",
    5: "/give 1 x1000000",
    6: "/give 3 x1000000",
    7: "/give 101 x1000000",
    8: "/give 102 x1000000",
    9: "/give 2 x1000000",
    11: "/giveall usables",
    12: "/avatar all lv80 r6 s10",
    13: "/avatar lineup lv80 r6 s10",
    14: "/avatar cur lv80 r6 s10",
    15: "/clear relics lv15",
    16: "/energy",
    17: "/gender female",
    18: "/heal",
    19: "/refill",
  };

  return (
    <div className="body">
      <div className="row">
        <div className="col-sm-1" />
        <div className="col-sm-11">
          <p className="cmdbeginner-description">
            <h2
              style={{
                fontWeight: "bold",
                color: "#9499ff",
                marginBottom: "10px",
              }}
            >
              {t("cmdbeginner")}
            </h2>
          </p>
          <p className="cmdbeginner-description">{t("cmdbeginner-des")}</p>

          <div className="button-container">
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("2")}
            >
              {t("cmdbeginner-1")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("1")}
            >
              {t("cmdbeginner-2")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("3")}
            >
              {t("cmdbeginner-3")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("4")}
            >
              {t("cmdbeginner-4")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("5")}
            >
              {t("cmdbeginner-5")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("6")}
            >
              {t("cmdbeginner-6")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("7")}
            >
              {t("cmdbeginner-7")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("9")}
            >
              {t("cmdbeginner-8")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("8")}
            >
              {t("cmdbeginner-9")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("10")}
            >
              {t("cmdbeginner-10")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("11")}
            >
              {t("cmdbeginner-11")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("12")}
            >
              {t("cmdbeginner-12")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("13")}
            >
              {t("cmdbeginner-13")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("14")}
            >
              {t("cmdbeginner-14")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("15")}
            >
              {t("cmdbeginner-15")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("16")}
            >
              {t("cmdbeginner-16")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("17")}
            >
              {t("cmdbeginner-17")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("18")}
            >
              {t("cmdbeginner-18")}
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleButtonClick("19")}
            >
              {t("cmdbeginner-19")}
            </button>
          </div>
          <br />
          <div className="selected-button">
            <p className="cmdbeginner-description">
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#9499ff",
                  marginBottom: "10px",
                }}
              >
                {t("command")}
              </h2>
            </p>
            <input
              type="text"
              value={selectedButton}
              readOnly
              ref={inputRef}
              className="copied-command-cmdbeginner"
              onClick={handleInputClick}
            />
          </div>
          {showNotification && (
            <div className="notification">{t("copySuccess")}</div>
          )}
          <div style={{ marginTop: "100px" }} />
        </div>
      </div>
    </div>
  );
}

export default HandleCmdbeginner;
