// src/Sidebar.js
import React, { useState} from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Col, Container, Row } from "react-bootstrap";
import "./Sidebar.css";
import Cmdbeginner from "./component/Cmdbeginner";
import Character from "./component/Character";
import Lightcones from "./component/Lightcones";
import Items from "./component/Items";
import Scene from "./component/Scene";
import Spawn from "./component/Spawn";
import Relics from "./component/Relics";
import PerfectRelics from "./component/PerfectRelics";
import JsonUpload from "./component/JsonUpload";

function Sidebar({ changeComponent }) {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("cmdbeginner");

  const changeLanguage = (option) => {
    i18n.changeLanguage(option.value);
  };

  const languageOptions = [
    { value: "en", label: "English (EN)" },
    { value: "vi", label: "Tiếng Việt (VI)" },
  ];

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const handleOptionClick = (key) => {
    setSelectedOption(key);
    
    // Thay đổi component khi nhấn vào nút
    switch (key) {
      case "cmdbeginner":
        changeComponent(<Cmdbeginner />);
        break;
      case "avatars":
        changeComponent(<Character />);
        break;
      case "lightcones":
        changeComponent(<Lightcones />);
        break;
      case "items":
        changeComponent(<Items />);
        break;
      case "scene":
        changeComponent(<Scene />);
        break;
      case "spawn":
        changeComponent(<Spawn />);
        break;
      case "relics":
        changeComponent(<Relics />);
        break;
      case "perfectrelics":
        changeComponent(<PerfectRelics />);
        break;
      case "jsonupload":
        changeComponent(<JsonUpload />);
        break;
      default:
        changeComponent(<Cmdbeginner />); // Giá trị mặc định
        break;
    }
  };

  return (
    <div>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        ☰
      </button>
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
        <Container>
          <Row className="justify-content-center">
            <Col>
              <div className="App">
                <h3 className="sidebar-logo">{t("logo")}</h3>
                <br />
                <p className="sidebar-title">{t("feature")}</p>
                {[
                  "cmdbeginner",
                  "lightcones",
                  "avatars",
                  "items",
                  "scene",
                  "spawn",
                  "relics",
                  "perfectrelics",
                  "jsonupload",
                ].map((key) => (
                  <p
                    key={key}
                    className={`sidebar-option ${selectedOption === key ? "selected" : ""}`}
                    onClick={() => {
                      handleOptionClick(key);
                      closeSidebar(); // Đóng sidebar khi nhấn
                    }}
                  >
                    {t(key)}
                  </p>
                ))}
                <hr />
                <p className="sidebar-title">{t("languages")}</p>
                <Select
                  className="sidebar-selection"
                  options={languageOptions}
                  onChange={changeLanguage}
                  placeholder={t("selectLanguage")}
                />
              </div>
              <div style={{ marginBottom: '100px' }} />
            </Col>
          </Row>
        </Container>
      </div>
      {isOpen && <div className="overlay" onClick={closeSidebar}></div>}
    </div>
  );
}

export default Sidebar;
